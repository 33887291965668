import { DropdownOption } from "components/Dropdown";
import _ from "lodash";
import * as Yup from "yup";
import {
  EmployeeModel,
  NewEmployeeModel,
} from "@dwo/shared/dist/models/employeeModel";

export const companiesOptions: DropdownOption[] = [
  { label: "TSU One", value: "TSU1" },
  { label: "Pinnacle", value: "Pinnacle" },
];

export const rolesOptions: DropdownOption[] = [
  // { label: "Admin", value: "admin" },
  // { label: "Subadmin", value: "subadmin" },
  { label: "Foreman", value: "foreman" },
  { label: "Supervisor", value: "supervisor" },
  { label: "Worker", value: "worker" },
];

export const regionsOptions: DropdownOption[] = [
  { label: "Fort Worth", value: 1 },
  { label: "Austin", value: 2 },
  { label: "Prosper", value: 3 },
  { label: "Oklahoma City", value: 4 },
  { label: "Tulsa", value: 5 },
  { label: "Amarillo", value: 6 },
  //{ label: "All", value: [1, 2, 3, 4] },
];

export const specialistFieldOptions: DropdownOption[] = [
  { label: "Employee:Bore Foreman", value: "Employee:Bore Foreman" },
  { label: "Employee:Bore Laborer", value: "Employee:Bore Laborer" },
  {
    label: "Employee:Electric:Main - Foreman",
    value: "Employee:Electric:Main - Foreman",
  },
  {
    label: "Employee:Electric:Service - Foreman",
    value: "Employee:Electric:Service - Foreman",
  },
  {
    label: "Employee:Electric:Service - Laborer",
    value: "Employee:Electric:Service - Laborer",
  },
  {
    label: "Employee:Executive Supervisor",
    value: "Employee:Executive Supervisor",
  },
  {
    label: "Employee:Executive Supervisor:Executive Supervisor",
    value: "Employee:Executive Supervisor:Executive Supervisor",
  },
  {
    label: "Employee:Executive Supervisor:Executive Supervisor:General Foreman",
    value: "Employee:Executive Supervisor:Executive Supervisor:General Foreman",
  },
  {
    label: "Employee:Gas:Foreman",
    value: "Employee:Gas:Foreman",
  },
  {
    label: "Employee:Gas:Laborer",
    value: "Employee:Gas:Laborer",
  },
  {
    label: "Employee:Management",
    value: "Employee:Management",
  },
  {
    label: "Employee:Mechanic",
    value: "Employee:Mechanic",
  },
  {
    label: "Employee:Officer",
    value: "Employee:Officer",
  },
  {
    label: "Employees:Bore:Foreman",
    value: "Employees:Bore:Foreman",
  },
  {
    label: "Employees:Bore:Foreman - OK",
    value: "Employees:Bore:Foreman - OK",
  },
  {
    label: "Employees:Bore:Laborer",
    value: "Employees:Bore:Laborer",
  },
  {
    label: "Employees:Bore:Laborer - OKC",
    value: "Employees:Bore:Laborer - OKC",
  },
  {
    label: "Employees:Electric:Laborer",
    value: "Employees:Electric:Laborer",
  },
  {
    label: "Employees:Electric:Main - Foreman",
    value: "Employees:Electric:Main - Foreman",
  },
  {
    label: "Employees:Electric:Main - Laborers",
    value: "Employees:Electric:Main - Laborers",
  },
  {
    label: "Employees:Electric:Service - Foreman",
    value: "Employees:Electric:Service - Foreman",
  },
  {
    label: "Employees:Electric:Service - Laborer",
    value: "Employees:Electric:Service - Laborer",
  },
  {
    label: "Employees:Executive Supervisor:Executive Supervisor",
    value: "Employees:Executive Supervisor:Executive Supervisor",
  },
  {
    label: "Employees:Executive Supervisor:Executive Supervisor - OK",
    value: "Employees:Executive Supervisor:Executive Supervisor - OK",
  },
  {
    label:
      "Employees:Executive Supervisor:Executive Supervisor - OK:General Foreman - OK",
    value:
      "Employees:Executive Supervisor:Executive Supervisor - OK:General Foreman - OK",
  },
  {
    label: "Employees:Executive Supervisor:Executive Supervisor - OK",
    value: "Employees:Executive Supervisor:Executive Supervisor - OK",
  },
  {
    label:
      "Employees:Executive Supervisor:Executive Supervisor:General Foreman",
    value:
      "Employees:Executive Supervisor:Executive Supervisor:General Foreman",
  },
  {
    label: "Employees:Gas:Foreman",
    value: "Employees:Gas:Foreman",
  },
  {
    label: "Employees:Gas:Foreman - OK",
    value: "Employees:Gas:Foreman - OK",
  },
  {
    label: "Employees:Gas:Laborer",
    value: "Employees:Gas:Laborer",
  },
  {
    label: "Employees:Gas:Laborer - OK",
    value: "Employees:Gas:Laborer - OK",
  },
  {
    label: "Employees:Management",
    value: "Employees:Management",
  },
  {
    label: "Employees:Mechanic",
    value: "Employees:Mechanic",
  },
  {
    label: "Mechanic - Oklahoma",
    value: "Mechanic - Oklahoma",
  },
  {
    label: "Pinnacle TCP",
    value: "Pinnacle TCP",
  },
];

/*
  ! This SupervisorId is the ID number the client uses (EmployeeId), NOT the SupervisorId from the database. It's named "SupervisorId" in the form because the user won't understand the difference between SupervisorId from the DB and EmployeeId from their company data. 
*/
export interface EmployeeFormFields {
  companyName: string;
  email: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  regionIds: number | any;
  role: string;
  specialistField: string;
  supervisorId: string;
  workPhone: string;
}

export const DEFAULT_NULL = "";
export const DEFAULT_EMAIL = "User Email";
export const DEFAULT_EMPLOYEE_ID = "0-00000-00";
export const DEFAULT_EMPTY = "- - -";
export const DEFAULT_FIRST_NAME = "User First Name";
export const DEFAULT_LAST_NAME = "User Last Name";
export const DEFAULT_NAME = "User Name";
export const DEFAULT_ROLE = "Foreman";
export const DEFAULT_SELECT_OPTION = "NO_SELECTION";
export const DEFAULT_SPECIALIST_FIELD = "No Class";
export const DEFAULT_USER_FORM_VALUES = [
  DEFAULT_EMAIL,
  DEFAULT_EMPTY,
  DEFAULT_FIRST_NAME,
  DEFAULT_LAST_NAME,
  DEFAULT_ROLE,
  DEFAULT_SELECT_OPTION,
];
export const commaRegex = new RegExp(/,/g);

export const getInitialEmployeeFormValues = (
  employeeData?: NewEmployeeModel,
  supervisorsData?: EmployeeModel,
) => {
  const employeeFormFields: EmployeeFormFields = {
    companyName: employeeData?.companyName || DEFAULT_SELECT_OPTION,
    email: employeeData?.email || DEFAULT_NULL,
    employeeId: employeeData?.employeeId || DEFAULT_EMPLOYEE_ID,
    firstName: employeeData?.firstName || DEFAULT_FIRST_NAME,
    lastName: employeeData?.lastName || DEFAULT_LAST_NAME,
    regionIds:
      _.isArray(employeeData?.regions) && employeeData?.regions?.length
        ? employeeData?.regions[0].id
        : DEFAULT_SELECT_OPTION,
    role: employeeData?.role || DEFAULT_SELECT_OPTION,
    specialistField: employeeData?.class || DEFAULT_SELECT_OPTION,
    supervisorId:
      supervisorsData?.supervisor?.employeeId || DEFAULT_EMPLOYEE_ID,
    workPhone: employeeData?.workPhone || DEFAULT_NULL,
  };

  return employeeFormFields;
};

export const validationEmployeeForm = (
  isEditing?: boolean,
  employeeData?: NewEmployeeModel,
) =>
  Yup.object({
    role: Yup.string()
      .required("User Role is required")
      .test(
        "match",
        "Select a User Role",
        (role) => role !== DEFAULT_SELECT_OPTION,
      ),
    regionIds: Yup.number().required("Region is required").moreThan(0),
    firstName: Yup.string()
      .required("First Name is required")
      .test(
        "match",
        "First Name not valid",
        (firstName) => firstName !== DEFAULT_FIRST_NAME,
      ),
    lastName: Yup.string()
      .required("Last Name is required")
      .test(
        "match",
        "Last Name not valid",
        (lastName) => lastName !== DEFAULT_LAST_NAME,
      ),
    companyName: Yup.string()
      .required("Company is required")
      .test(
        "match",
        "Select a Company",
        (companyName) => companyName !== DEFAULT_SELECT_OPTION,
      ),
    specialistField: Yup.string()
      .required("Specialist Field is required")
      .test(
        "match",
        "Select a Specialist Field",
        (specialistField) => specialistField !== DEFAULT_SELECT_OPTION,
      ),
    employeeId: Yup.string()
      .required("Employee ID is required")
      .test("match", "Employee ID format is not valid", (idNumber) => {
        const idNumberRegex = new RegExp(/^[0-9]\-[0-9]{5}\-[0-9]{2}$/);
        if (!idNumber || idNumber === "" || idNumber === DEFAULT_EMPLOYEE_ID) {
          return false;
        }
        return idNumberRegex.test(String(idNumber));
      }),
    supervisorId: Yup.string()
      .required("Supervisor ID is required")
      .test("match", "Supervisor ID format is not valid", (idNumber) => {
        const idNumberRegex = new RegExp(/^[0-9]\-[0-9]{5}\-[0-9]{2}$/);
        if (!idNumber || idNumber === "" || idNumber === DEFAULT_EMPLOYEE_ID) {
          return false;
        }
        return idNumberRegex.test(String(idNumber));
      }),
    employeeEmail: Yup.string().test("match", "Invalid Email", (email) => {
      const emailRegex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      );
      if (!email || email === "" || email === DEFAULT_EMAIL) {
        return true;
      }
      return emailRegex.test(String(email));
    }),
    workPhone: Yup.string().test(
      "match",
      "Invalid phone Number",
      (workPhone) => {
        const phoneRegex = new RegExp(
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
        );
        if (!workPhone || workPhone === "") {
          return true;
        }
        return phoneRegex.test(String(workPhone));
      },
    ),
  });

export const getDropdownOptionLabel = (
  options: DropdownOption[],
  value: string = DEFAULT_EMPTY,
) => {
  const option = options.find(
    (option: DropdownOption) => option.value === value,
  );

  return option ? option.label : DEFAULT_EMPTY;
};
